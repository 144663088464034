import { useEffect, useState } from "react";
import CurrentUser, {checkSignedIn, auth} from "./firebase";
import { Link } from "react-router-dom";
import logo from '../images/logo.png';

const Navbar = () => {

    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        function getData() {
            const status = checkSignedIn();
            setSignedIn(status);
        }

        getData();
    },[auth]);

    return ( 
        <div className="navbar">
            <Link to="/home" id="home-link">
                <img src={logo} alt="logo" id="navbar-logo"/>
                <span className="text">Art of the Heart</span>
            </Link>

            {signedIn &&

                <div className="links">

                    <p><Link to={"../concerts"}>Concerts</Link> <CurrentUser /></p>
                    
                </div>

            }
            
            {!signedIn &&


                <div className="links">

                    <p id="sign-in-link"><Link to={"../sign_in"}>Sign In</Link></p>
                </div>

            }
            
        </div>
    );
}
 
export default Navbar;