import { useState } from "react";
import { resetPassword } from "../util/firebase";
import OpenEnded from "../util/OpenEnded";
import { useNavigate } from "react-router-dom";

const PasswordResetEmail = () => {
    
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const submitForm = event => {
        event.preventDefault();
        resetPassword(email);
        navigate("/sign_in");
    
    }

    return ( 
        <div className="password-reset-email">

            <h2>Reset Password</h2>
            <form onSubmit={submitForm}>

                <OpenEnded question="Email:" setResponse={setEmail} type="email"/><br/>
                
                <button type="submit">Send Password Reset Email</button>
            </form>

        </div>
     );
}
 
export default PasswordResetEmail;