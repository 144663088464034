import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getConcerts } from "../../util/firebase";
import Navbar from "../../util/Navbar";
import Footer from "../../util/Footer";
import Dropdown, { statesList } from "../../util/Dropdown";

const ConcertOverviewPage = () => {

    const [concerts, setConcerts] = useState([]);
    const [concertState, setConcertState] = useState("");

    useEffect(() => {
        
        async function getData() {
            
            const data = await getConcerts();

            const date = new Date();
            
            setConcerts(data.filter(concert => (new Date(concert.getConcertIsoString())) > date));

        }

        getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const concertStateChange = (state) => {
        setConcertState(state);
    }

    function getDateTime(concert) {
        const dt = new Date(concert.getConcertIsoString());
        const datetime = dt.toLocaleString('en-US', { timeZone: concert.getConcertTimezoneLocale() });
        return (datetime.substring(0, datetime.length - 6) + datetime.substring(datetime.length - 2));
    }

    return ( 
        <div className="concert-overview-page page-content">
            <Navbar/>

            <div className="content">
            <h2>Concerts</h2>

            <Dropdown question="Filter Concerts by State: " setResponse={concertStateChange} options={[""].concat(statesList)} id="concert-state"/>

            { concertState !== "" &&
            concerts.filter(concert => concert.getState() === concertState).map((concert, i) =>
                
                <div key={i}>

                    <h3>{concert.getFacilityName()}</h3>
                    <p>{getDateTime(concert)} {concert.getConcertTimezone()}</p>
                    <p>{concert.getCity()}, {concert.getState()} {concert.getZipCode()}</p>
                    <Link to={`/concerts/${concert.getId()}`}>More Details</Link>

                </div>
    
            )
            }

            { concertState === "" &&
            concerts.map((concert, i) =>
            
                
                <div key={i}>

                    <h3>{concert.getFacilityName()}</h3>
                    <p>{getDateTime(concert)} {concert.getConcertTimezone()}</p>
                    <p>{concert.getCity()}, {concert.getState()} {concert.getZipCode()}</p>
                    <Link to={`/concerts/${concert.getId()}`}>More Details</Link>

                </div>
               
                
            )
            }
            </div>

            <Footer/>

        </div>
     );
}
 
export default ConcertOverviewPage;