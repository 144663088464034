import OpenEnded from "../util/OpenEnded";
import Dropdown from "../util/Dropdown";
import { statesList } from "../util/Dropdown";

const NewFacility = ({facility, setFacility}) => {

    const setFacilityName = (facilityName) => {
        setFacility({...facility, facilityName: facilityName});
    }

    const setFacilityStreetAddress = (facilityStreetAddress) => {
        setFacility({...facility, streetAddress: facilityStreetAddress});
    }

    const setFacilityCity = (facilityCity) => {
        setFacility({...facility, city: facilityCity});
    }

    const setFacilityState = (facilityState) => {
        setFacility({...facility, state: facilityState});
    }

    const setFacilityZip = (facilityZip) => {
        setFacility({...facility, zipCode: facilityZip});
    }

    const setFacilityPhone = (facilityPhone) => {
        setFacility({...facility, phoneNumber: facilityPhone});
    }

    const setFacilityEmail = (facilityEmail) => {
        setFacility({...facility, email: facilityEmail});
    }

    const setFacilityWebsite = (facilityWebsite) => {
        setFacility({...facility, website: facilityWebsite});
    }

    return ( 

        <div className="new-facility">

            <OpenEnded question="Facility Name:" setResponse={setFacilityName}/><br/>
            <OpenEnded question="Street Address:" setResponse={setFacilityStreetAddress}/><br/>
            <OpenEnded question="City:" setResponse={setFacilityCity}/><br/>
            <Dropdown question="State:" options={statesList} setResponse={setFacilityState} id="facility-state"/><br/>
            <OpenEnded question="Zip:" setResponse={setFacilityZip}/><br/>
            <OpenEnded question="Phone:" setResponse={setFacilityPhone}/><br/>
            <OpenEnded question="Email:" setResponse={setFacilityEmail}/><br/>
            <OpenEnded question="Website:" setResponse={setFacilityWebsite}/><br/>

        </div>

     );
}
 
export default NewFacility;