import MultipleChoice from "../util/MultipleChoice";
import OpenEnded from "../util/OpenEnded";
import { useEffect, useState } from "react";
import Dropdown, { timeZoneList } from "../util/Dropdown";
import { ConcertInformation, FacilityInformation } from "./InformationClass";
import { createConcert, getFacilities, getFacilityInformation, getStudentNames, checkAdmin } from "../util/firebase";
import NewFacility from "./NewFacility";
import { Navigate } from "react-router-dom";

const NewConcert = () => {

    const [facilityStatus, setFacilityStatus] = useState('');

    const [facility, setFacility] = useState(new FacilityInformation());

    const [concertInfo, setConcertInfo] = useState(new ConcertInformation());

    const [concertId, setConcertId] = useState('');

    const [studentList, setStudentList] = useState([]);

    const [admin, setAdmin] = useState(true);

    const setDate = (date) => {
        setConcertInfo({...concertInfo, date: date});
    }

    const setTime = (time) => {
        setConcertInfo({...concertInfo, time: time});
    }

    const setTimeZone = (timeZone) => {
        setConcertInfo({...concertInfo, timezoneLocale: timeZone});
    }

    const setDescription = (description) => {
        setConcertInfo({...concertInfo, description: description});
    }

    const setLogistics = (logistics) => {
        setConcertInfo({...concertInfo, logistics: logistics});
    }

    const setDirections = (directions) => {
        setConcertInfo({...concertInfo, directions: directions});
    }

    const setEmailStatus = (emailStatus) => {
        setConcertInfo({...concertInfo, emailStatus: emailStatus});
    }

    const setAreaRep = (areaRep) => {
        const uid = areaRep.split("[")[1].split("]")[0];
        setConcertInfo({...concertInfo, areaRep: uid});
    }

    const setMasksReq = (masksReq) => {
        setConcertInfo({...concertInfo, masksReq: masksReq});
    }

    const setVaccinesReq = (vaccinesReq) => {
        setConcertInfo({...concertInfo, vaccinesReq: vaccinesReq});
    }    

    const [possibleFacilityNames, setPossibleFacilityNames] = useState([]);

    useEffect(() => {

        if(facilityStatus === "Yes"){
            getFacilityNames();
            
        }
    }, [facilityStatus]);

    useEffect(() => {
        async function getData (){
            const students = await getStudentNames();
            setStudentList(students);

            const admin = await checkAdmin();
            setAdmin(admin);
        }
        getData();

        //console.log(concertInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getFacilityNames(){
        
        const data = await getFacilities();
        setPossibleFacilityNames(data);

    }

    async function oldFacilityChange(facility){

        const facilityName = facility.split(", ")[0];
        const facilityCity = facility.split(", ")[1];
        const facilityState = facility.split(", ")[2];

        const facilityInfo = await getFacilityInformation(facilityName, facilityCity, facilityState);

        setFacility(facilityInfo);
        setConcertInfo({...concertInfo, facilityId: facilityInfo.id});

        //console.log(concertInfo);

    }

    const submitForm = (event) => {
        event.preventDefault();
        createConcert(facility, concertInfo, setConcertId);
    }

    return ( 

        <div className="new-concert">

            <form onSubmit={submitForm}>

            { 
            concertId === '' &&
            <div>

                <h2>New Concert</h2>
            
                <h3>Facility Information</h3>

                <MultipleChoice 
                    question="Have we hosted a concert at this facility before?" 
                    options={["Yes", "No"]}
                    setResponse={setFacilityStatus} 
                    id="new-facility"
                />
            </div>

            }
            {
            concertId === '' && facilityStatus === "No" && 

            <NewFacility facility={facility} setFacility={setFacility}/>
            
            }

            {
            concertId === '' && facilityStatus === "Yes" &&

            <Dropdown question="Facility Name:" options={possibleFacilityNames} setResponse={oldFacilityChange} id="facility-name"/>
            }

            {
            concertId === '' && 
            <div>
                <h3>Concert Details</h3>

                <OpenEnded question="Date:" setResponse={setDate} type="date"/> <br/>
                <OpenEnded question="Time:" setResponse={setTime} type="time"/> <br/>
                <Dropdown question="Timezone:" setResponse={setTimeZone} options={timeZoneList}/> <br/>
                <OpenEnded question="Description:" setResponse={setDescription} textarea={true}/> <br/>
                <OpenEnded question="Facility Logistics:" setResponse={setLogistics} textarea={true}/> <br/>
                <OpenEnded question="Facility Directions:" setResponse={setDirections} textarea={true}/> <br/>
                <MultipleChoice question="Is the logistics email ready to be sent out?" options={["Yes", "No"]} setResponse={setEmailStatus} id="logistics-email"/>
                <Dropdown question="Area Rep:" options={studentList} setResponse={setAreaRep} id="area-rep"/>
                
                <p>COVID policies (check all that apply)</p>
                <MultipleChoice question="" options={["Masks required."]} setResponse={setMasksReq} type="checkbox"/>
                <MultipleChoice question="" options={["Vaccines required."]} setResponse={setVaccinesReq} type="checkbox"/>


                <button type="submit">Create New Concert</button>

            </div>
            }

            {
            concertId !== '' && <Navigate to={`/concerts/${concertId}`}/>

            }

            </form>

            {!admin && <Navigate to="/home"/>}

        
        </div>

    );
}
 
export default NewConcert;