export class StudentInformation {
    constructor(firstName="", lastName="", pronouns="", dateOfBirth="", streetAddress="", city="", state="", zipCode="", school="", emergencyContact) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.pronouns = pronouns;
        this.dateOfBirth = dateOfBirth;
        this.streetAddress = streetAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.school = school;

        this.EmergencyContact = class {
            constructor(firstNameEC="", lastNameEC="", phoneNumberEC="", emailEC="", relationshipEC="") {
                this.firstName = firstNameEC;
                this.lastName = lastNameEC;
                this.phoneNumber = phoneNumberEC;
                this.email = emailEC;
                this.relationship = relationshipEC;
            };
        }

        if (emergencyContact == null) {

            this.emergencyContact = new this.EmergencyContact();

        } else {
            this.emergencyContact = emergencyContact;
        }

        
    }

    getFirstName() {

        return this.firstName;
    }

    getLastName() {
        return this.lastName;
    }

    getPronouns() {
        return this.pronouns;
    }

    getDateOfBirth() {
        return this.dateOfBirth;
    }

    getStreetAddress() {
        return this.streetAddress;
    }

    getCity() {
        return this.city;
    }

    getState() {
        return this.state;
    }

    getZipCode() {
        return this.zipCode;
    }

    getSchool() {
        return this.school;
    }

    getEmergencyContact() {
        return this.emergencyContact;
    }

    getEmergencyContactFirstName() {

        return this.emergencyContact.firstName;
    }

    getEmergencyContactLastName() {
        return this.emergencyContact.lastName;
    }

    getEmergencyContactPhoneNumber() {
        return this.emergencyContact.phoneNumber;
    }

    getEmergencyContactEmail() {
        return this.emergencyContact.email;
    }

    getEmergencyContactRelationship() {
        return this.emergencyContact.relationship;
    }
    log() {
        console.log(this)
    }
    
}

export class FacilityInformation {

    constructor(id=null, facilityName="", streetAddress="", city="", state="", zipCode="", phoneNumber="", email="", website="") {
        this.id = id;
        this.facilityName = facilityName;
        this.streetAddress = streetAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.website = website;
    }

    getId() {
        return this.id;
    }

    getFacilityName() {
        return this.facilityName;
    }

    getStreetAddress() {
        return this.streetAddress;
    }

    getCity() {
        return this.city;
    }

    getState() {
        return this.state;
    }

    getZipCode() {
        return this.zipCode;
    }

    getPhoneNumber() {
        return this.phoneNumber;
    }

    getEmail() {
        return this.email;
    }

    getWebsite() {
        return this.website;
    }

}

export class ConcertInformation {
    constructor(id=null, date="", time="", timezoneLocale="", timezone, isoString="" ,description="", facilityId=null, facilityName="", address="", city="", state="", zipCode="", logistics="", directions="", emailStatus="", areaRep="", masksReq="Masks not required.", vaccinesReq="Vaccines not required.") {
        this.id = id;
        this.date = date;
        this.time = time;
        this.timezoneLocale = timezoneLocale;
        this.timezone = timezone;
        this.isoString = isoString;
        this.description = description;
        this.facilityId = facilityId;
        this.facilityName = facilityName;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;

        this.logistics = logistics;
        this.directions = directions;
        this.emailStatus = emailStatus;
        this.areaRep = areaRep;
        this.masksReq = masksReq;
        this.vaccinesReq = vaccinesReq;
    }

    getId() {
        return this.id;
    }

    getConcertDate() {
        return this.date;
    }

    getConcertTime() {
        return this.time;
    }

    getConcertTimezoneLocale() {
        return this.timezoneLocale;
    }

    getConcertTimezone() {
        return this.timezone;
    }

    getConcertIsoString() {
        return this.isoString;
    }

    getConcertDescription() {
        return this.description;
    }

    getFacilityId() {
        return this.facilityId;
    }

    getFacilityName() {
        return this.facilityName;
    }

    getAddress() {
        return this.address;
    }

    getCity() {
        return this.city;
    }

    getState() {
        return this.state;
    }

    getZipCode() {
        return this.zipCode;
    }

    getLogistics() {
        return this.logistics;
    }

    getDirections() {
        return this.directions;
    }

    getEmailStatus() {
        return this.emailStatus;
    }

    getAreaRep() {
        return this.areaRep;
    }

    getMasksReq() {
        return this.masksReq;
    }

    getVaccinesReq() {
        return this.vaccinesReq;
    }


}