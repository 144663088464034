import { useState } from "react";
import { newUser } from "../util/firebase.js"
import MultipleChoice from "../util/MultipleChoice.js";
import OpenEnded from "../util/OpenEnded";
import { Link } from "react-router-dom";

/*
user: amanda@artoftheheartmusic.org
password: password

user: admin@app.artoftheheartmusic.org
password: Connect.22

*/


const Signup = ({setSignedUp}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [userType, setUserType] = useState('');

    const submitForm = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match. Try again.");

        } else if (!checkbox) {
            setErrorMessage("You must accept the terms and conditions to sign up.");
    
        } else if (email === '') {
            setErrorMessage("You must enter an email address.");
        } else if (password === '') {
            setErrorMessage("You must enter a password.");

        
        } else if (userType === '') {
            setErrorMessage("You must select a user type.");
        
        }
         else {
            setErrorMessage('');
            newUser(email, password, userType, setErrorMessage, setSignedUp);
        }

    }

    const checkBoxChange = (event) => {
        setCheckbox(event.target.checked);
    }

    return (
        <div className="sign-up">
            
            <h2>Sign up</h2>

            <form onSubmit={submitForm}>

                <OpenEnded question="Email:" setResponse={setEmail} type="email"/><br/>

                <OpenEnded question="Password:" setResponse={setPassword} type="password"/><br/>

                <OpenEnded question="Confirm Password:" setResponse={setConfirmPassword} type="password"/><br/>

                <MultipleChoice question="User Type:" options={["Student"]} setResponse={setUserType} id="user-type"/><br/>
                
                {//<MultipleChoice question="User Type:" options={["Student", "Facility"]} setResponse={setUserType} id="user-type"/>
                }
                
                <input type="checkbox" value="" onChange={checkBoxChange}/><span>I accept the <Link to="/terms">terms and conditions</Link>.</span>

                <br/>

                <button 
                    type="submit"
                    disabled={!(checkbox)}
                >Sign Up</button><br/><br/>

                <div className="error-message"> {errorMessage} </div>
            </form>

        </div>
    );
}
 
export default Signup;