import { Link } from "react-router-dom";
import Navbar from "../util/Navbar";
import Footer from "../util/Footer";

const ErrorPage = () => {
    return ( 
        <div className="error-page page-content">
            <Navbar/>
            <div className="content">
            <h2>404</h2>
            <p>Page not found</p>
            <Link to={"../"}>Go Home</Link>
            </div>
            <Footer/>
        </div>
     );
}
 
export default ErrorPage;