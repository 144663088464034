import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StudentInformation } from "../form/InformationClass";
import { getStudentInformation, updateStudentInformation } from "../util/firebase";
import InformationEditor from "../util/InformationEditor";
import { useNavigate } from "react-router-dom";
import { statesList } from "../util/Dropdown";
import Navbar from "../util/Navbar";
import Footer from "../util/Footer";

const ManageAccountPage = () => {

    const [studentInformation, setStudentInformation] = useState(new StudentInformation());

    const navigate = useNavigate();

    useEffect(() => {

        async function getData() {
            const result = await getStudentInformation();
            //console.log(result);

            if (result === null) {
                navigate("../sign_in");
            }
            setStudentInformation(result);
        }

        getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateValue = (value, document, property) => {
        updateStudentInformation(document, property, value);
    }

    return ( 
        <div className="manage-account-page page-content">
            <Navbar/>
            <div className="content">
            <h2>Manage Account</h2>
            <p>Manage your account information here.</p>
    
            <InformationEditor field="First Name: " value={studentInformation.getFirstName()} setValue={(document, property, value) => updateValue(document, property, value)} document="info" property="firstName"/>
            <InformationEditor field="Last Name: " value={studentInformation.getLastName()} property="lastName" document="info" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Pronouns: " value={studentInformation.getPronouns()} property="pronouns" document="info" setValue={(document, property, value) => updateValue(document, property, value)} dropdownList={["He/Him", "She/Her", "They/Them", "Other"]}/>
            <InformationEditor field="Date of Birth: " value={studentInformation.getDateOfBirth()} property="dateOfBirth" document="info" type="date" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Street Address: " value={studentInformation.getStreetAddress()} property="streetAddress" document="info" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="City: " value={studentInformation.getCity()} property="city" document="info" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="State: " value={studentInformation.getState()} property="state" document="info" setValue={(document, property, value) => updateValue(document, property, value)} dropdownList={statesList}/>
            <InformationEditor field="Zip Code: " value={studentInformation.getZipCode()} property="zipCode" document="info" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="School: " value={studentInformation.getSchool()} property="school" document="info" setValue={(document, property, value) => updateValue(document, property, value)}/>

            <h2>Emergency Contact Information</h2>
            <InformationEditor field="First Name: " value={studentInformation.getEmergencyContactFirstName()} property="firstName" document="emergencyContact" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Last Name: " value={studentInformation.getEmergencyContactLastName()} property="lastName" document="emergencyContact" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Phone Number: " value={studentInformation.getEmergencyContactPhoneNumber()} property="phoneNumber" document="emergencyContact" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Email: " value={studentInformation.getEmergencyContactEmail()} property="email" document="emergencyContact" type="email" setValue={(document, property, value) => updateValue(document, property, value)}/>
            <InformationEditor field="Relationship: " value={studentInformation.getEmergencyContactRelationship()} property="relationship" document="emergencyContact" setValue={(document, property, value) => updateValue(document, property, value)}/>

            <Link to="../change_password">Change Password</Link>
            <br/>
            <Link to="../change_email">Change Email</Link>
            </div>
            <Footer/>
            
        </div>
     );
}
 
export default ManageAccountPage;