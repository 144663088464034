import { useEffect, useState } from "react";
import { getStudentInformation } from "../util/firebase";
import { StudentInformation } from "../form/InformationClass";

/**
 * 
 * @param {String} student_uid 
 * @returns {JSX}
 */
const Student = ({student_uid}) => {

    const [studentInformation, setStudentInformation] = useState(new StudentInformation());
    
    useEffect(() => {
        async function getData() {

            const result = await getStudentInformation(student_uid);

            setStudentInformation(result);
        }

        getData();

    }, [student_uid]);
    
    return ( 
    
        <div className="student">

            <h3>{studentInformation.getFirstName()} {studentInformation.getLastName()} ({studentInformation.getPronouns()})</h3>
            <p>Date of Birth: {studentInformation.getDateOfBirth()}</p>
            <p>Address: {studentInformation.getStreetAddress()}</p>
            <p>{studentInformation.getCity()}, {studentInformation.getState()} {studentInformation.getZipCode()}</p>
            <p>School: {studentInformation.getSchool()}</p>
            <p>Emergency Contact: {studentInformation.getEmergencyContactFirstName()} {studentInformation.getEmergencyContactLastName()}</p>
            <p>Phone Number: {studentInformation.getEmergencyContactPhoneNumber()}</p>
            <p>Email: {studentInformation.getEmergencyContactEmail()}</p>
            <p>Relationship: {studentInformation.getEmergencyContactRelationship()}</p>

        </div> 
    
    );
}
 
export default Student;