/*

npm run build
npm run start
firebase deploy
firebase emulators:start
firebase hosting:channel:deploy CHANNEL_ID

 */


import { useRoutes }from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import ManageAccountPage from "./pages/ManageAccountPage";
import StudentInformationPage from "./pages/admin/StudentInformationPage";
import NewConcertPage from "./pages/admin/NewConcertPage";
import ConcertPage from "./pages/concerts/ConcertPage";
import ConcertOverviewPage from "./pages/concerts/ConcertOverviewPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import ChangeEmailPage from "./pages/ChangeEmailPage";
import PasswordResetPage from "./pages/PasswordResetPage";

//import logo from './logo.svg';
//import './App.css';


function App() {

  let element = useRoutes([
    {
      path: "/",
      element: <HomePage/>,
      children: [
        { 
          path: "/index", 
          element: <HomePage/> 
        },
        { 
          path: "/home", 
          element: <HomePage/> 
        },
      ]
    }, 
    {
      path: "*",
      element: <ErrorPage/>

    }, 
    {
      path: "/sign_in",
      element: <SignInPage/>
    },
    {
      path: "/sign_up",
      element: <SignUpPage/>
    },
    {
      path: "/reset_password",
      element: <PasswordResetPage />
    }, 

    {
      path: "/manage_account",
      element: <ManageAccountPage/>
    },

    {
      path: "/change_password",
      element: <ChangePasswordPage />
    },

    {
      path: "/change_email",
      element: <ChangeEmailPage />
    },
    {
      path: "/admin/student_information",
      element: <StudentInformationPage/>
    },
    {
      path: "/admin/new_concert",
      element: <NewConcertPage/>
    },
    {
      path: "/concerts",
      element: <ConcertOverviewPage/>
    },
    {
      path: "/concerts/:concertId",
      element: <ConcertPage/>
    },
    {
      path: "/terms",
      element: <TermsPage/>
    },
    {
      path: "/privacy_policy",
      element: <PrivacyPolicyPage/>
    }

  ]);

  return element;

}

export default App;
