import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ConcertInformation, FacilityInformation} from "../../form/InformationClass";
import { statesList, timeZoneList } from "../../util/Dropdown";
import { checkAdmin, 
    getConcertInformation, 
    getFacilities, checkSignedIn,
    getFacilityInformation, updateConcertInformation, getStudentNames,
    createFacility, changeConcertFacility, studentSignUpForConcert, studentCancelSignUpForConcert, checkStudentSignedUpForConcert, getStudentList, getStudentInformation
} from "../../util/firebase";
import InformationEditor from "../../util/InformationEditor";
import NewFacility from "../../form/NewFacility";
import Navbar from "../../util/Navbar";
import Footer from "../../util/Footer";
import MultipleChoice from "../../util/MultipleChoice";

const ConcertPage = () => {

    const [concert, setConcert] = useState(new ConcertInformation());
    const [facility, setFacility] = useState(new FacilityInformation());
    const [admin, setAdmin] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [signedUp, setSignedUp] = useState(false);
    const [signedIn, setSignedIn] = useState(false);
    const [datetime, setDatetime] = useState('');
    
    const [facilityList, setFacilityList] = useState([]);
    const [areaRepList, setAreaRepList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [newFacility, setNewFacility] = useState(false);

    const [checkbox, setCheckbox] = useState(false);

    const [areaRepName, setAreaRepName] = useState('');

    const { concertId } = useParams();

    useEffect(() => {

        async function getData() {

            const concert  = await getConcertInformation(concertId);
            const admin_status = await checkAdmin();

            setConcert(concert);
            setAdmin(admin_status);

            const dt = new Date(concert.getConcertIsoString());
            const datetime = dt.toLocaleString('en-US', { timeZone: concert.getConcertTimezoneLocale() });
            //console.log(datetime);
            setDatetime(datetime.substring(0, datetime.length - 6) + datetime.substring(datetime.length - 2));

            setTimeout(() => {
                checkSignedIn().then(
                    (status) => {
                        //console.log(status);
                        setSignedIn(status);
                    }
                ); 
            }, 500);

            if (admin_status) {

                const facility = await getFacilityInformation(concert.getFacilityName(), concert.getCity(), concert.getState());
                setFacility(facility);

                const students = await getStudentList(concertId);
                setStudentList(students);

                const areaRepInfo = await getStudentInformation(concert.getAreaRep());
                setAreaRepName(areaRepInfo.getFirstName() + " " + areaRepInfo.getLastName() + " [" + concert.getAreaRep() + "]");

                const areaReps = await getStudentNames();
                setAreaRepList(areaReps);
            }
            
            if (!admin_status) {

                checkStudentSignedUpForConcert(concertId, setSignedUp);

            }

        }

        getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        async function getData(){
            const concert  = await getConcertInformation(concertId);
            setConcert(concert);

        }

        setTimeout(() => {getData();}, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facility]);

    async function getFacilityList() {

        const facilities = await getFacilities();
        setFacilityList(facilities);
        setFacilityList([...facilities, "New Facility"]);

    }

    const editButtonClicked = () => {
        setEditMode(true);
        getFacilityList();
    }

    

    async function updateValue (value, property) {

        if (property==="areaRep"){

            const uid = value.split("[")[1].split("]")[0];
            await updateConcertInformation(property, concertId, facility.getId(), uid);
        } else {
            await updateConcertInformation(property, concertId, facility.getId(), value);
            

            const datetimeProperties = ["date", "time", "timezoneLocale"];
            if (datetimeProperties.includes(property)) {
                const concert  = await getConcertInformation(concertId);
                setConcert(concert);

                const dt = new Date(concert.getConcertIsoString());
                const datetime = dt.toLocaleString('en-US', { timeZone: concert.getConcertTimezoneLocale() });
                //console.log(datetime);
                setDatetime(datetime.substring(0, datetime.length - 6) + datetime.substring(datetime.length - 2));

            }
        }
        
    }

    async function updateFacility (newFacility) {

        if (newFacility === "New Facility") {
            setNewFacility(true);
        } else {
            setNewFacility(false);
            const facilityName = newFacility.split(", ")[0];
            const facilityCity = newFacility.split(", ")[1];
            const facilityState = newFacility.split(", ")[2];

            const facilityInfo = await getFacilityInformation(facilityName, facilityCity, facilityState);

            setFacility(facilityInfo);

            changeConcertFacility(concertId, facilityInfo.getId());
        }

    }

    const createNewFacility = () => {
        createFacility(facility);
        setNewFacility(false);
        setFacility(facility);
        changeConcertFacility(concertId, facility.getId());
        
    }

    return ( 
        <div className="concert-page page-content">

            <Navbar/>
            <div className="content">
            {!editMode && 

            <div className="concert-page-view-mode">

                <h2>{concert.getFacilityName()}</h2>
                <p>{datetime} {concert.getConcertTimezone()}</p>
                <p>{concert.getConcertDescription()}</p>
                <p>{concert.getAddress()}</p>
                <p>{concert.getCity()}, {concert.getState()} {concert.getZipCode()}</p>

            </div>
            
            }

            {
                !signedIn &&
                <p>To sign up for the concert, <Link to="/sign_up">create an account</Link> or <Link to="/sign_in">sign in</Link>.</p>
                
            }

            {!admin && !signedUp && signedIn &&
            <div className="concert-page-view-mode">
                <MultipleChoice question="" options={["I understand that excepting extreme circumstances, I will attend the concert or cancel my sign-up."]} type="checkbox" setResponse={setCheckbox}/>
                <button onClick={()=> studentSignUpForConcert(concertId, setSignedUp)} disabled={!(checkbox)}>Sign up for this concert</button>
            </div>
            }

            {!admin && signedUp &&
            <button onClick={()=> studentCancelSignUpForConcert(concertId, setSignedUp)}>Cancel sign up for this concert</button>
            }

            {
                editMode && !newFacility &&
                <div className="concert-page-edit-mode">

                <h3>Change Facility</h3>
                <InformationEditor field="Facility: " 
                                    dropdownList={facilityList}
                                    value={facility.facilityName + ", " + facility.city + ", " + facility.state}
                                    setValue={updateFacility}
                />
                </div>

            }

            {
                editMode && newFacility &&
                <div className="concert-page-edit-mode">

                    <h3>New Facility</h3>

                    <NewFacility facility={facility} setFacility={setFacility}/>

                    <button onClick={() => createNewFacility()}>Create New Facility</button>

                </div>

            }

            {editMode && !newFacility && 
            <div className="concert-page-edit-mode">
                <h3>Facility Properties</h3>
                <p>Note that editing these properties will change the properties for all concerts associated with this facility.</p>
                <InformationEditor field="Facility Name: " value={concert.getFacilityName()} property="facilityName" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Address: " value={concert.getAddress()} property="address" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="City: " value={concert.getCity()} property="city" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="State: " value={concert.getState()} property="state" setValue={(value, property)=>updateValue(value, property)} dropdownList={statesList}/>
                <InformationEditor field="Zip Code: " value={concert.getZipCode()} property="zipCode" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Phone Number: " value={facility.phoneNumber} property="phoneNumber" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Website: " value={facility.website} property="website" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Email: " value={facility.email} property="email" setValue={(value, property)=>updateValue(value, property)}/>
                </div>
            }

            {editMode && 
                <div className="concert-page-edit-mode">
                <h3>Concert Properties</h3>
                <InformationEditor field="Concert Date: " value={concert.getConcertDate()} property="date" setValue={(value, property)=>updateValue(value, property)} type="date"/>
                <InformationEditor field="Concert Time: " value={concert.getConcertTime()} property="time" setValue={(value, property)=>updateValue(value, property)} type="time"/>
                <InformationEditor field="Concert Timezone: " value={concert.getConcertTimezoneLocale()} property="timezoneLocale" setValue={(value, property)=>updateValue(value, property)} dropdownList={timeZoneList}/>
                <InformationEditor field="Concert Description: " value={concert.getConcertDescription()} property="description" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Logistics: " value={concert.getLogistics()} property="logistics" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Directions: " value={concert.getDirections()} property="directions" setValue={(value, property)=>updateValue(value, property)}/>
                <InformationEditor field="Email Ready to be sent? " value={concert.getEmailStatus()} property="emailStatus" setValue={(value, property)=>updateValue(value, property)} dropdownList={["Yes", "No"]}/>
                <InformationEditor field="Area Rep: " value={areaRepName} property="areaRep" setValue={(value, property)=>updateValue(value, property)} dropdownList={areaRepList}/>
                <InformationEditor field="Masks Required? " value={concert.getMasksReq()} property="masksReq" setValue={(value, property)=>updateValue(value, property)} dropdownList={["Masks required.", "Masks not required."]}/>
                <InformationEditor field="Vaccines Required? " value={concert.getVaccinesReq()} property="vaccinesReq" setValue={(value, property)=>updateValue(value, property)} dropdownList={["Vaccines required.", "Vaccines not"]}/>
                
                <button onClick={()=> setEditMode(false)}>Done</button>
            </div>
            }

            {admin && !editMode && 
            <div className="concert-page-view-mode">
                <p>Logistics: {concert.getLogistics()}</p>
                <p>Directions: {concert.getDirections()}</p>
                <p>Email Ready to be sent? {concert.getEmailStatus()}</p>
                <p>Area Rep: {areaRepName}</p>
                <p>Masks Required? {concert.getMasksReq()}</p>
                <p>Vaccines Required? {concert.getVaccinesReq()}</p>

                <button onClick={editButtonClicked}>Edit Concert</button>
            </div>
            }

            {admin && !editMode && studentList.length > 0 &&
            
            <div className="concert-page-view-mode">
                <h3>Students Signed Up</h3>
                <ul>
                    {studentList.map((student, i) => 
                        <li key={i}>{student.firstName} {student.lastName} ({student.email})</li>
                    )
                    }
                </ul>
            </div>
            
            }

            {admin && !editMode && studentList.length === 0 &&
            <p>There are no students signed up</p>
            }
            </div>

            <Footer/>

        </div>     
     );
}
 
export default ConcertPage;