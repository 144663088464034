import ChangeEmail from "../auth/ChangeEmail";
import Footer from "../util/Footer";
import Navbar from "../util/Navbar";

const ChangeEmailPage = () => {
    return ( 
        <div className="change-email-page page-content">
            <Navbar />
            <div className="content">
                <ChangeEmail />
            </div>
            <Footer />
        </div>
     );
}
 
export default ChangeEmailPage;