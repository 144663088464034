import NewConcert from "../../form/NewConcert";
import Footer from "../../util/Footer";
import Navbar from "../../util/Navbar";


const NewConcertPage = () => {
    return ( 
        <div className="admin-new-concert-page page-content">
            <Navbar/>
            <div className="content">
                <NewConcert/>
            </div>
            <Footer/>
        </div>
     );
}
 
export default NewConcertPage;