/**
 * 
 * @param {string} question question to be asked
 * @param {Function} setResponse react UseState function to set response to question
 * @param {string} [ type = text ] type of input field; default is text (see HTML input types for more options)
 * @param {string} [ value = "" ] default value of input field; default is empty string
 * @returns open-ended question text field with label
 */

const OpenEnded = ({question, setResponse, type="text", value="", textarea=false}) => {

    const responseChange = (event) => {
        setResponse(event.target.value);
    }
    
    return ( 
        <span className="open-ended">
            
            <label>{question}</label>

            { textarea && <textarea name="question" onChange={responseChange} defaultValue={value}/> }
            { !textarea && <input type={type} name="question" onChange={responseChange} defaultValue={value}/>}

        </span>
     );
}
 
export default OpenEnded;