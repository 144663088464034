import Signup from "../auth/Signup";
import { Link } from "react-router-dom";
import { useState } from "react";
import StudentRegistration from "../form/StudentRegistration";
import Navbar from "../util/Navbar";
import Footer from "../util/Footer";

const SignUpPage = () => {

    const [signedUp, setSignedUp] = useState(false);

    return ( 
        <div className="sign-up-page page-content">

            <Navbar/>
            <div className="content">
            <Signup setSignedUp={setSignedUp}/>

            {!signedUp && 
            <div className="links">
                <Link to={"../sign_in"}>Sign In Instead</Link>
                <br/>
                <Link to={"../reset_password"}>Forgot Your Password?</Link>
            </div>
            }

            {signedUp &&
                <div className="student-registration">

                    <StudentRegistration/>
                    
                </div>
            }
            </div>
            <Footer/>

        </div>
     );
}
 
export default SignUpPage;