import Signin from "../auth/Signin";
import { Link } from "react-router-dom";
import Navbar from "../util/Navbar";
import Footer from "../util/Footer";

const SignInPage = () => {
    return ( 
        <div className="sign-in-page page-content">

            <Navbar/>
            <div className="content">

            <Signin/>

            <Link to={"../sign_up"}>Sign Up Instead</Link>
            <br/>
            <Link to={"../reset_password"}>Forgot Your Password?</Link>
            </div>
            <Footer/>

        </div>

     );
}
 
export default SignInPage;