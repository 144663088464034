import { useState } from "react";
import { changeEmail } from "../util/firebase";
import OpenEnded from "../util/OpenEnded";
import { useNavigate } from "react-router-dom";

const ChangeEmail = () => {

    const [password, setPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const navigate = useNavigate();

    const submitForm = (event) => {
        event.preventDefault();
        changeEmail(password, newEmail);
        navigate("/manage_account");
    }

    return (  
        <div className="change-email">

            <h2>Change Email</h2>
            <form onSubmit={submitForm}> 

                <OpenEnded question="Password:" setResponse={setPassword} type="password"/><br/>

                <OpenEnded question="New Email:" setResponse={setNewEmail} type="email"/><br/>

                <button type="submit">Change Email</button>

            </form>

        </div>
    );
}
 
export default ChangeEmail;