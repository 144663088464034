import OpenEnded from "./OpenEnded";
import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";

const InformationEditor = ({field, value, setValue, type="text", document=null, property=null, dropdownList=null}) => {

    const [editMode, setEditMode] = useState(false);
    const [response, setResponse] = useState(value);

    const updateInformation = (event) => {
        event.preventDefault()
        //console.log(response);
        setEditMode(false);

        if ((document !== null) && (property !== null)){
            setValue(response, document, property);
        } else if (property !== null) {
            setValue(response, property);
        } else {
            setValue(response);
        }
    }

    useEffect(() => {
        setResponse(value);
    }, [value]);

    return ( 
        <div className="information-editor">

            {
                editMode && (dropdownList === null) &&
                <div>
                    <form onSubmit={updateInformation}>
                        <OpenEnded question={field} setResponse={setResponse} value={response} type={type}/>
                        <button type="submit">Update</button>

                    </form>
                </div>
            }

            {
                editMode && (dropdownList !== null) &&
                <div>
                    <form onSubmit={updateInformation}>
                        <Dropdown question={field} options={dropdownList} setResponse={setResponse} defaultValue={response}/>
                        <button type="submit">Update</button>
                    </form>
                </div>
            }
            {
                !(editMode) &&
                <div>
                    <p>{field} {response} <button onClick={() => {setEditMode(true)}}>Edit</button></p>
                </div>
            }

        </div>
     );
}
 
export default InformationEditor;