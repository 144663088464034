import { useState } from "react";
import { logIn } from "../util/firebase";
import OpenEnded from "../util/OpenEnded";
import { Navigate } from "react-router-dom";

const Signin = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loginSuccess, setLoginSuccess] = useState(false);

    const formSubmit = event => {
        event.preventDefault();
        logIn(email, password, setErrorMessage, setLoginSuccess);
    }

    return ( 
        <div className="sign-in">

            { !loginSuccess && 

            <div className="sign-in">
                    
                <h2>Sign in</h2>

                <form onSubmit={formSubmit} >
                
                    <OpenEnded question="Email:" setResponse={setEmail} type="email"/>
                    <br/>
                    <OpenEnded question="Password:" setResponse={setPassword} type="password"/>
                    <br/>
                    <button type="submit">Sign In</button>

                    <div className="error-message"> {errorMessage} </div>
                </form>

            </div> 

            }

            {loginSuccess && <Navigate to="../"/>}

        </div>

        
        
    );
}
 
export default Signin;