import Navbar from "../util/Navbar";
import PasswordResetEmail from "../auth/PasswordResetEmail";
import Footer from "../util/Footer";

const PasswordResetPage = () => {
    return ( 
        <div className="password-reset-page page-content">
            <Navbar />
            <div className="content">
                <PasswordResetEmail />
            </div>
            <Footer />
        </div>
     );
}
 
export default PasswordResetPage;