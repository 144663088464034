import Navbar from "../util/Navbar";
import ChangePassword from "../auth/ChangePassword";
import Footer from "../util/Footer";

const ChangePasswordPage = () => {
    return ( 
        <div className="change-password-page page-content">
            <Navbar />
            <div className="content">
                <ChangePassword />
            </div>
            <Footer />
        </div>
     );
}
 
export default ChangePasswordPage;