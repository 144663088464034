import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <div className="footer">
            <p>
                ©2023 Art of the Heart, a <a href="https://drive.google.com/file/d/1-gtkTWwmaOGOvUjfDAgRZNXeZ03Dw92x/view">501(c)(3)</a> nonprofit. All rights reserved.    |   <Link to="/terms">TERMS</Link>   |   <Link to="/privacy_policy">PRIVACY</Link>
            </p>
        </div> 
    );
}
 
export default Footer;