const Dropdown = ({question, options, setResponse, id, defaultValue="default"}) => {

    const responseChange = (event) => {
        setResponse(event.target.value);
    }

    return ( 
        <span className="dropdown">

            <label htmlFor={id}>{question}</label>
            <select name={id} id={id} onChange={responseChange} defaultValue={defaultValue}>
                <option hidden disabled value="default"> -- select an option -- </option>
                {
                    options.map((option, i) =>
                        <option key={i} value={option}>{option}</option>
                    )
                }
            </select>

        </span>
    );
}
 
export default Dropdown;

export const statesList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];

export const timeZoneList = [
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "Pacific/Honolulu"
];
