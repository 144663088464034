// import functions from firebase using npm
import { initializeApp } from "firebase/app";

import { 
    getAuth, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged, 
    sendEmailVerification,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    updateEmail,
    sendPasswordResetEmail,
    updateProfile
} 
from "firebase/auth";

import { 
    getFirestore,
    doc,
    addDoc,
    setDoc,
    updateDoc,
    getDoc,
    getDocs,
    collection,
    query,
    where,
    deleteDoc,
} 
from "firebase/firestore";

import { StudentInformation, 
        FacilityInformation,
        ConcertInformation,
} 
from "../form/InformationClass";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// firebase configuration from the firebase console -> project settings -> general
const firebaseConfig = {
    apiKey: "AIzaSyCg8KffZwXeQkq_TBBCqT6jT58Zi5utAro",
    authDomain: "connect-app-87d8b.firebaseapp.com",
    projectId: "connect-app-87d8b",
    storageBucket: "connect-app-87d8b.appspot.com",
    messagingSenderId: "935095193160",
    appId: "1:935095193160:web:6d29457141a0374308927d",
    measurementId: "G-M4JEN1R9Z9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firebase Firestore and get a reference to the service
const db = getFirestore(app);

// possible error messages to be displayed to the user.
const possibleErrorMessages = {
    "auth/email-already-in-use": "Email already in use. Please use another email, or sign in instead.",
    "auth/user-not-found": "User not found. Try signing up instead.",
    "auth/invalid-email": "Invalid email. Please use another email.",
    "auth/missing-email": "Please enter an email.",
    "auth/internal-error": "There was an error. Please check your email and password and try again.",
    "auth/weak-password": "Password should be at least 6 characters long. Please use a stronger password.",
    "auth/wrong-password": "Wrong password. Please try again.",
    "auth/too-many-requests": "Too many attempts with the wrong password. Reset your password or try again later.",
};

/**
 * creates a new user with email and password
 * @param {String} email 
 * @param {String} password 
 * @param {Function} setErrorMessage 
 */
export function newUser(email, password, userType, setErrorMessage, setSignedUp) {

    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

            // Signed in 
            //console.log(email, password);
            
            const user = userCredential.user;
            //console.log(user);

            sendEmailVerification(auth.currentUser)
                .then(() => {
                    //console.log("sent")
                    // Email verification sent!

                    setErrorMessage("Please verify your email by clicking the link in the email sent to your inbox.")
                });

            if (userType === "Student") {

                setDoc(doc(db, "student", user.uid), {
                    areaRep: false,
                });

                setDoc(doc(db, "student", user.uid, "personal", "info"), {
                    email: user.email
                });

                setDoc(doc(db, "student", user.uid, "personal", "emergencyContact"), {
                });


            } else if (userType === "Facility") {
                    
                setDoc(doc(db, "facility", user.uid), {
    
                });

            }

            setSignedUp(true);
        })
        .catch((error) => {
            const errorCode = error.code;
            //const errorMessage = error.message;

            setErrorMessage(possibleErrorMessages[errorCode]);
            
            //console.log(errorCode, errorMessage);

        });
};

/**
 * logs in the user with email and password
 * @param {String} email 
 * @param {String} password 
 * @param {Function} setErrorMessage 
 * @param {Function} setLoginSuccess
 */
export function logIn(email, password, setErrorMessage, setLoginSuccess){

    console.log(password);

    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

            const user = userCredential.user;
            console.log(user);
            setLoginSuccess(true);

        })
        .catch((error) => {
            
            const errorCode = error.code;
            //const errorMessage = error.message;

            setErrorMessage(possibleErrorMessages[errorCode]);
            
            //console.log(errorCode, errorMessage);
        })

}


/**
 * logs out the current user
 */
export function logOut () {
    signOut(auth).then(()=> {
        //console.log("signed out");
        window.location.reload(false);

    }).catch((error) => {
        //console.log(error);
    });
}


let currentUser = null;

onAuthStateChanged(auth, (user) => {
    //Sconsole.log(user);
    if (user) {
      // User is signed in
        currentUser = user.email;

    } else {
      // User is signed out

        currentUser = "Not Signed In";
    }
});

export async function checkSignedIn(){

    if (currentUser === "Not Signed In") {
        return false;
    } else {
        return true; 
    }
}

/**
 * gets the current user and displays it
 * @returns {JSX} JSX element that displays the current user
 */
const CurrentUser = () => {
    const [user, setUser] = useState("Not Signed In");
/*
    useEffect(() => {
        console.log(currentUser);
        setUser(currentUser);
    }, [currentUser]);
*/
    useEffect(() => {

        if (currentUser === null) {
            //console.log("null");
            setTimeout(() => {setUser(currentUser)}, 500);
        } else {
            //console.log(currentUser);
            setUser(currentUser);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

/*
    //console.log(auth.currentUser);
    onAuthStateChanged(auth, (user) => {
        console.log(user);
        if (user) {
          // User is signed in
          setCurrentUser(user.email);
    
        } else {
          // User is signed out
    
            setCurrentUser("Not Signed In");
        }
    });
    */
/*
    useEffect(() => {
        async function getUser() {
            console.log(auth.currentUser);

            await checkSignedIn().then((result) => {
                console.log(result);
                if (result) {
                    
                    setCurrentUser(auth.currentUser.email);
                    //console.log(currentUser);
                } else {
                    setCurrentUser("Not Signed In");
                }
            });
        
            
        }

        getUser();
    }, [auth]);
  */  
   /*
    useEffect(() => {
        async function getUser() {

            console.log(auth.currentUser);
        
            if (auth.currentUser !== null) {
                setCurrentUser(auth.currentUser.email);
                //console.log(currentUser);
            } else {
                setCurrentUser("Not Signed In");
            }
        }

        getUser();
    }, []);
*/
    return ( 
        <span className="current-user">
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,600,0,0" />
            <span className="material-symbols-outlined">account_circle</span> {user} 
            <span className="material-symbols-outlined">arrow_drop_down</span>

            {user === "Not Signed In" &&
            <span className="dropdown-content">
            <Link to={"/sign_in"}>Sign In</Link>
            </span>
            }
            { user !== "Not Signed In" && 
            <span className="dropdown-content">
            <Link to={"/manage_account"}>Manage Account</Link>
            <Link to={""} onClick={logOut}>Sign Out</Link>
            </span>
            }
            
        </span>
     );
}
 
export default CurrentUser;

/**
 * changes the password of the current user
 * @param {String} currentPassword 
 * @param {String} newPassword 
 */
export function changePassword (currentPassword, newPassword) {
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
    )

    reauthenticateWithCredential(auth.currentUser, credential);

    updatePassword(auth.currentUser, newPassword).then(() => {
        //console.log("password changed", newPassword);
    }).catch((error) => {
        //console.log(error);
    });
}

/**
 * changes the current user's email
 * @param {String} currentPassword 
 * @param {String} newEmail 
 */
export function changeEmail (currentPassword, newEmail) {

    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
    )

    reauthenticateWithCredential(auth.currentUser, credential);

    updateEmail(auth.currentUser, newEmail).then(() => {
        //console.log("email changed", newEmail);
    }).catch((error) => {
        //console.log(error);
    })

    updateDoc(doc(db, "student", auth.currentUser.uid, "personal", "info"), {
        email: auth.currentUser.email
    });

}

/**
 * sends a password reset email to the user
 * @param {String} email 
 */
export function resetPassword (email) {
    sendPasswordResetEmail(auth, email)
    .then(() => {
        //console.log("password reset email sent");
    }).catch((error) => {
        //console.log(error); 
    })
}

/**
 * @returns {JSX} dictionary with functions to convert student information to firestore format and back
 */
const studentInformationConverter = {
    toFirestoreInfo: (studentInformation) => {

        return {
            firstName: studentInformation.firstName,
            lastName: studentInformation.lastName,
            pronouns: studentInformation.pronouns,
            dateOfBirth: studentInformation.dateOfBirth,
            address: studentInformation.streetAddress,
            city: studentInformation.city,
            state: studentInformation.state,
            zipCode: studentInformation.zipCode,
            school: studentInformation.school,
            
        };    
    },
    toFirestoreEmergencyContact: (studentInformation) => {
        return {
            firstName: studentInformation.emergencyContact.firstName,
            lastName: studentInformation.emergencyContact.lastName,
            phoneNumber: studentInformation.emergencyContact.phoneNumber,
            email: studentInformation.emergencyContact.email,
            relationship: studentInformation.emergencyContact.relationship,
        };
    },

    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options).personal;

        const studentInfo = new StudentInformation(data.info.firstName,
                                                    data.info.lastName,
                                                    data.info.pronouns,
                                                    data.info.dateOfBirth,
                                                    data.info.email,
                                                    data.info.address,
                                                    data.info.city,
                                                    data.info.state,
                                                    data.info.zipCode,
                                                    data.info.school);
        
        const emergencyContact = new studentInfo.EmergencyContact(data.emergencyContact.firstName,
                                                                    data.emergencyContact.lastName,
                                                                    data.emergencyContact.phoneNumber,
                                                                    data.emergencyContact.email,
                                                                    data.emergencyContact.relationship);

        studentInfo.emergencyContact = emergencyContact;

        return studentInfo;

    }
}

/**
 * stores student information in firebase
 * @param {Object} info dictionary of information to be stored in firestore using the converter
 */
export function studentRegistration(info) {
    //console.log(auth.currentUser);

    updateDoc(doc(db, "student", auth.currentUser.uid, "personal", "info"), studentInformationConverter.toFirestoreInfo(info));
    updateDoc(doc(db, "student", auth.currentUser.uid, "personal", "emergencyContact"), studentInformationConverter.toFirestoreEmergencyContact(info));

    updateProfile(auth.currentUser, {displayName: info.firstName + " " + info.lastName});

}

/**
 * gets student information from firestore
 * @param {String} student_uid for admins only, the uid of the student to get information for
 * @returns {Promise<StudentInformation>} student information of current student or student_uid if admin or null if not logged in
 */
export async function getStudentInformation(student_uid) {

    if (auth.currentUser === null) {
        return null;
    } else {

        let uid = null;

        const admin = await checkAdmin();

        if (admin){
            uid = student_uid;
        } else {
            uid = auth.currentUser.uid;
        }

        let studentInfo = new StudentInformation();

        await getDoc(doc(db, "student", uid, "personal", "info")).then((doc) => {

            studentInfo.firstName = doc.data().firstName;
            studentInfo.lastName = doc.data().lastName;
            studentInfo.pronouns = doc.data().pronouns;
            studentInfo.dateOfBirth = doc.data().dateOfBirth;
            studentInfo.streetAddress = doc.data().streetAddress;
            studentInfo.city = doc.data().city;
            studentInfo.state = doc.data().state;
            studentInfo.zipCode = doc.data().zipCode;
            studentInfo.school = doc.data().school;

        }).catch((error) => {
            console.log(error);
        });
        
        let emergencyContact = new studentInfo.EmergencyContact();

        await getDoc(doc(db, "student", uid, "personal", "emergencyContact")).then((doc) => {

            emergencyContact.firstName = doc.data().firstName;
            emergencyContact.lastName = doc.data().lastName;
            emergencyContact.phoneNumber = doc.data().phoneNumber;
            emergencyContact.email = doc.data().email;
            emergencyContact.relationship = doc.data().relationship;

        }).catch((error) => {
            console.log(error);
        });

        studentInfo.emergencyContact = emergencyContact;

        return studentInfo;
    }
}

/**
 * gets a specific information field for a student
 * @param {String} document emergency_contact or info
 * @param {String} property 
 */
export function getStudentInformationSpecific(document, property) {
    getDoc(doc(db, "student", auth.currentUser.uid, "personal", document)).then((doc) => {
        //console.log(doc.data()[property]);
        const value = doc.data()[property];
        //console.log(value);
        return value;
    }).catch((error) => {
        console.log(error);
    });
}

/**
 * updates student information for a student for a specific property
 * @param {String} document emergency_contact or info 
 * @param {String} property 
 * @param {String} newValue 
 */
export function updateStudentInformation(document, property, newValue) {
    updateDoc(doc(db, "student", auth.currentUser.uid, "personal", document), {
        [property]: newValue
    });
}

/**
 * gets the email of the current user
 * @returns {Promise<String>} email of current user or "none" if not logged in
 */
export async function getUser(){
    if (auth.currentUser) {
        return auth.currentUser.email;
    } else {
        return "none";
    }
}

/**
 * checks if the current user is an admin
 * @returns {Promise<Boolean>} true if current user is an admin, false otherwise
 */
export async function checkAdmin(){

    let admin = false;

    if (auth.currentUser !== null) {
        await getDoc(doc(db, "admin", auth.currentUser.uid)).then((doc) => {

            //console.log(doc);

            if (doc._document !== null) {
                admin = true;
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return admin;

}

/**
 * gets the students in the database
 * @returns {Array} array of student ids
 */
export async function getStudents(){

    const students = [];

    await getDocs(collection(db, "student")).then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
            students.push(doc.id);
        });

    }).catch((error) => {
        console.log(error);

    });

    return students;
}

export async function getStudentNames(){
    const student_uids = [];
    const students = [];

    await getDocs(collection(db, "student")).then((querySnapshot) => {
            
            querySnapshot.forEach((doc) => {

                student_uids.push(doc.id);

            });
    
        }).catch((error) => {
            console.log(error);
    
        });

    for (let index = 0; index < student_uids.length; index++) {
        
        await getDoc(doc(db, "student", student_uids[index], "personal", "info")).then((doc) => {
            students.push(doc.data().firstName + " " + doc.data().lastName + " [" + student_uids[index] + "]");
        }).catch((error) => {
            console.log(error);
        });
        
    }

    return students;
}

/**
 * creates a concert using the facility and concert information
 * @param {FacilityInformation} facility 
 * @param {ConcertInformation} concert 
 * @param {Function} setConcertId
 */
export async function createConcert(facility, concert, setConcertId){

    //console.log(facility);
    //console.log(concert);

    let facilityId = concert.facilityId;

    if (facilityId === null) {
        
        const facilityRef = await createFacility(facility);

        facilityId = facilityRef.id;

    }

    let concertId = "";

    facility.facilityName.split(" ").forEach((word) => {
        concertId += word.toLowerCase();
        concertId += "-";
    });

    concertId += facility.city;
    concertId += "-";
    
    concert.date.split("/").forEach((date) => {
        concertId += date;
    });

    const datetime = new Date(concert.date + " " + concert.time);
    const timeString = datetime.toLocaleString('en-US', { timeZone: concert.timezoneLocale, timeZoneName: 'short' });
    //console.log(timeString);
    const timeZone = timeString.substring(timeString.length - 3);
    const isoString = new Date(concert.date + " " + concert.time + " " + timeZone).toISOString();
    
    setDoc(doc(db, "concert", concertId), {
        adminfacilityId: facilityId,
        date: concert.date,
        time: concert.time,
        timezoneLocale: concert.timezoneLocale,
        timezone: timeZone,
        isoString: isoString,
        description: concert.description,
        address: facility.streetAddress,
        city: facility.city,
        state: facility.state,
        zipCode: facility.zipCode,
        facilityName: facility.facilityName,
        logistics: concert.logistics,
        directions: concert.directions,
        emailStatus: concert.emailStatus,
        areaRep: concert.areaRep,
        masksReq: concert.masksReq,
        vaccinesReq: concert.vaccinesReq,
        logisticsEmailSent: false,
        reminderEmailSent: false,
        postConcertEmailSent: false,
    });

    setDoc(doc(db, "admin-facility", facilityId, "concerts", concertId), {
        date: concert.date,
        time: concert.time,
        description: concert.description,


    });

    setConcertId(concertId);

}

/**
 * creates a facility in the database
 * @param {FacilityInformation} facility
 * @returns {Promise<String>} reference to facility in database
*/

export async function createFacility(facility){

    const facilityRef = await addDoc(collection(db, "admin-facility"), {
        facilityName: facility.facilityName,
        address: facility.streetAddress,
        city: facility.city,
        state: facility.state,
        zipCode: facility.zipCode,
        phoneNumber: facility.phoneNumber,
        email: facility.email,
        website: facility.website,
    });

    return facilityRef;

}

/**
 * get faciilities in the database
 * @returns {Promise<Array>} array of facilities
 */
export async function getFacilities(){

    const facilities = [];

    await getDocs(collection(db, "admin-facility")).then((querySnapshot) => {

        //console.log(querySnapshot);

        querySnapshot.forEach((doc) => {
            facilities.push(doc.data().facilityName + ", " + doc.data().city + ", " + doc.data().state);
        });

    }).catch((error) => {
        console.log(error);
    });

    return facilities;

}

/**
 * gets the facility information for a specific facility based on their name, city, and state
 * @param {String} name 
 * @param {String} city 
 * @param {String} state 
 * @returns {Promise<FacilityInformation>} facility information
 */
export async function getFacilityInformation(name, city, state){

    let facilityInfo = new FacilityInformation();

    await getDocs(query(collection(db, "admin-facility"),where("facilityName", "==", name), where("city", "==", city), where("state", "==", state))).then((querySnapshot) => {
            
        querySnapshot.forEach((doc) => {

            facilityInfo.id = doc.id;
            facilityInfo.facilityName = doc.data().facilityName;
            facilityInfo.streetAddress = doc.data().address;
            facilityInfo.city = doc.data().city;
            facilityInfo.state = doc.data().state;
            facilityInfo.zipCode = doc.data().zipCode;
            facilityInfo.phoneNumber = doc.data().phoneNumber;
            facilityInfo.email = doc.data().email;
            facilityInfo.website = doc.data().website;
        });
    }).catch((error) => {
        console.log(error);
    });


    return facilityInfo;
    
}

/**
 * gets concert information based on concertId
 * @param {String} concertId 
 * @returns {Promise <ConcertInformation>} concert information
 */
export async function getConcertInformation(concertId){

    let concertInfo = new ConcertInformation();

    await getDoc(doc(db, "concert", concertId)).then((doc) => {

        concertInfo.id = doc.id;
        concertInfo.date = doc.data().date;
        concertInfo.time = doc.data().time;
        concertInfo.timezoneLocale = doc.data().timezoneLocale;
        concertInfo.timezone = doc.data().timezone;
        concertInfo.isoString = doc.data().isoString;
        concertInfo.description = doc.data().description;
        concertInfo.facilityId = doc.data().adminfacilityId;
        concertInfo.facilityName = doc.data().facilityName;
        concertInfo.address = doc.data().address;
        concertInfo.city = doc.data().city;
        concertInfo.state = doc.data().state;
        concertInfo.zipCode = doc.data().zipCode;
        concertInfo.logistics = doc.data().logistics;
        concertInfo.directions = doc.data().directions;
        concertInfo.emailStatus = doc.data().emailStatus;
        concertInfo.areaRep = doc.data().areaRep;
        concertInfo.masksReq = doc.data().masksReq;
        concertInfo.vaccinesReq = doc.data().vaccinesReq;

    });

    return concertInfo;

}

/**
 * gets the concert information for the concerts in the database
 * @returns {Promise<Array>} array of concerts
 */
export async function getConcerts(){
    
    const concerts = [];

    await getDocs(collection(db, "concert")).then((querySnapshot) => {

        querySnapshot.forEach((doc) => {

            let concertInfo = new ConcertInformation();

            concertInfo.id = doc.id;
            concertInfo.date = doc.data().date;
            concertInfo.time = doc.data().time;
            concertInfo.timezoneLocale = doc.data().timezoneLocale;
            concertInfo.timezone = doc.data().timezone;
            concertInfo.isoString = doc.data().isoString;
            concertInfo.description = doc.data().description;
            concertInfo.facilityId = doc.data().adminfacilityId;
            concertInfo.facilityName = doc.data().facilityName;
            concertInfo.address = doc.data().address;
            concertInfo.city = doc.data().city;
            concertInfo.state = doc.data().state;
            concertInfo.zipCode = doc.data().zipCode;
            concertInfo.logistics = doc.data().logistics;
            concertInfo.directions = doc.data().directions;
            concertInfo.emailStatus = doc.data().emailStatus;
            concertInfo.areaRep = doc.data().areaRep;
            concertInfo.masksReq = doc.data().masksReq;
            concertInfo.vaccinesReq = doc.data().vaccinesReq;

            concerts.push(concertInfo);
        });

    }).catch((error) => {
        console.log(error);
    });

    return concerts;

}

/**
 * updates concert information
 * @param {String} property 
 * @param {String} concertId 
 * @param {String} facilityId 
 * @param {String} newValue 
 */
export async function updateConcertInformation(property, concertId, facilityId, newValue) {

    const concertProperties = ["isoString", "description", "logistics", "directions", "emailStatus", "areaRep", "masksReq", "vaccinesReq"];

    const concertFacilityProperties = ["facilityName", "address", "city", "state", "zipCode"];

    const facilityProperties = ["email", "phoneNumber", "website"];

    const datetimeProperties = ["date", "time", "timezoneLocale"];
    
    if (concertProperties.includes(property)) {
        updateDoc(doc(db, "concert", concertId), {
            [property]: newValue
        });

        updateDoc(doc(db, "admin-facility", facilityId, "concerts", concertId), {
            [property]: newValue
        });
    } else if (concertFacilityProperties.includes(property)) {
        updateDoc(doc(db, "concert", concertId), {
            [property]: newValue
        });

        updateDoc(doc(db, "admin-facility", facilityId), {
            [property]: newValue
        });
    } else if (facilityProperties.includes(property)) {
        updateDoc(doc(db, "admin-facility", facilityId), {
            [property]: newValue
        });
    } else if (datetimeProperties.includes(property)) {

        const oldDateTime = {};

        await getDoc(doc(db, "concert", concertId)).then((doc) => {
            oldDateTime["date"] = doc.data().date;
            oldDateTime["time"] = doc.data().time;
            oldDateTime["timezoneLocale"] = doc.data().timezoneLocale;
            oldDateTime["timezone"] = doc.data().timezone;
        });

        if (property === "date") {

            const isoString = new Date(newValue +" " + oldDateTime["time"] + " " + oldDateTime["timezone"]).toISOString();
            updateDoc(doc(db, "concert", concertId), {
                [property]: newValue,
                isoString: isoString
            });
        }
        else if (property === "time") {

            const isoString = new Date(oldDateTime["date"] + " " + newValue + " " + oldDateTime["timezone"]).toISOString();

            updateDoc(doc(db, "concert", concertId), {
                [property]: newValue,
                isoString: isoString
            });
        }
        else if (property === "timezoneLocale") {

            const datetime = new Date(oldDateTime["date"] + " " + oldDateTime["time"]);
            const timeString = datetime.toLocaleString('en-US', { timeZone: newValue, timeZoneName: 'short' });
            const timezone = timeString.substring(timeString.length - 3);
            const isoString = new Date(oldDateTime["date"] + " " + oldDateTime["time"] + " " + timezone).toISOString();

            updateDoc(doc(db, "concert", concertId), {
                [property]: newValue,
                timezone: timezone,
                isoString: isoString
            });
        }
    }
}

export async function changeConcertFacility(concertId, facilityId){

    let oldFacilityId = ""
    
    await getDoc(doc(db, "concert", concertId)).then((doc) => {
        oldFacilityId = doc.data().adminfacilityId;
    });

    updateDoc(doc(db, "concert", concertId), {
        adminfacilityId: facilityId
    });

    getDoc(doc(db, "admin-facility", facilityId)).then((doc) => {

        updateConcertInformation("facilityName", concertId, facilityId, doc.data().facilityName);
        updateConcertInformation("address", concertId, facilityId, doc.data().address);
        updateConcertInformation("city", concertId, facilityId, doc.data().city);
        updateConcertInformation("state", concertId, facilityId, doc.data().state);
        updateConcertInformation("zipCode", concertId, facilityId, doc.data().zipCode);

    });

    await getDoc(doc(db, "admin-facility", oldFacilityId, "concerts", concertId)).then((old_doc) => {
        setDoc(doc(db, "admin-facility", facilityId, "concerts", concertId), {
            date: old_doc.data().date,
            time: old_doc.data().time,
            description: old_doc.data().description,
        });
    });

    deleteDoc(doc(db, "admin-facility", oldFacilityId, "concerts", concertId));

}


export async function studentSignUpForConcert(concertId, setSignedUp) {

    setDoc(doc(db, "student", auth.currentUser.uid, "concerts", concertId), {
        signedUp: true,
        attended: false
    });
    
    getDoc(doc(db, "student", auth.currentUser.uid, "personal", "info")).then((doc_data) => {

        setDoc(doc(db, "concert", concertId, "students", auth.currentUser.uid), {
            firstName: doc_data.data().firstName,
            lastName: doc_data.data().lastName,
            email: doc_data.data().email
        });
    }); 

    setSignedUp(true);

}

export async function studentCancelSignUpForConcert(concertId, setSignedUp) {
    
    deleteDoc(doc(db, "student", auth.currentUser.uid, "concerts", concertId));
    
    deleteDoc(doc(db, "concert", concertId, "students", auth.currentUser.uid));
    
    setSignedUp(false);
    
}

export async function checkStudentSignedUpForConcert(concertId, setSignedUp) {

    await getDoc(doc(db, "student", auth.currentUser.uid, "concerts", concertId)).then((doc) => {
        if (doc.exists()) {
            setSignedUp(true);
        } else {
            setSignedUp(false);
        }
    });

}

export async function getStudentList(concertId) {

    const studentList = [];

    await getDocs(collection(db, "concert", concertId, "students")).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            studentList.push(doc.data());
        });
    }).catch((error) => {
        console.log(error);
    });

    //console.log(studentList);
    return studentList;

}



export async function getConcertsSignedUpFor(){

    const concertIds = [];
    const concerts = [];

    await getDocs(collection(db, "student", auth.currentUser.uid, "concerts")).then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
            concertIds.push(doc.id);
        });

    }).catch((error) => {
        console.log(error);
    });

    for (let index = 0; index < concertIds.length; index++) {
        const id = concertIds[index];
        await getConcertInformation(id).then((concert) => {
            concerts.push(concert);
        });
    }

    return concerts;

}