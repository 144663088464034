import { useEffect, useState } from "react";
import { changePassword } from "../util/firebase";
import OpenEnded from "../util/OpenEnded";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {

    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordNewConfirm, setPasswordNewConfirm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (passwordNew !== passwordNewConfirm) {
            setErrorMessage("New passwords do not match, please double check and try again");
        } else {
            setErrorMessage("");
        }
    }, [passwordNew, passwordNewConfirm]);

    const navigate = useNavigate();

    const submitForm = (event) => {
        event.preventDefault();
        changePassword(passwordCurrent, passwordNew);
        navigate("/manage_account");
    }

    return ( 
        <div className="change-password">
            <h2>Change Password</h2>

            <form onSubmit={submitForm}>

                <OpenEnded question="Current Password" type="password" setResponse={setPasswordCurrent}/><br/>
                <OpenEnded question="New Password" type="password" setResponse={setPasswordNew}/><br/>
                <OpenEnded question="Confirm New Password" type="password" setResponse={setPasswordNewConfirm}/><br/>

                <button type="submit">Change Password</button>

                <div className="error-message"> {errorMessage} </div>
            </form>

        </div>
     );
}
 
export default ChangePassword;