import { useEffect, useState } from "react";
import { checkAdmin, getStudents } from "../../util/firebase";
import Student from "../../admin/Student"
import Navbar from "../../util/Navbar";
import Footer from "../../util/Footer";
import { Navigate } from "react-router-dom";

const StudentInformationPage = () => {

    const [students, setStudents] = useState([]);
    const [admin, setAdmin] = useState(true);

    useEffect(() => {

        async function getData() {

            const admin = await checkAdmin();
            setAdmin(admin);
            //console.log(admin);

            if (admin) {
                console.log(admin);
                const result = await getStudents();
                setStudents(result);
            }
        }

        getData();

    }, []);

    return ( 
        <div className="student-information-page page-content">

            <Navbar/>

            <div className="content">
                <h2>Student Information</h2>

                {
                    students.map((student, i) => 
                        <Student student_uid={student} key={i}/>
                    )
                }
            </div>

            <Footer/>

            {!admin && 
            <Navigate to="/"/>}

        </div>
     );
}
 
export default StudentInformationPage;